@import "../../../assets/styles/variable";

.header {
    transition: all 0.4s ease;
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;

    & .container {
        margin: auto;
    }

    &__in {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        height: 80px;
    }

    &.sticky-active {
        background-color: rgba($dark-color2, 0.9);
        box-shadow: 0 10px 10px -10px rgba($dark-color2, 0.9);
    }
}


@media screen and (max-width: 991px) {
    .header {
        background-color: rgba($dark-color2, 0.9);   
        // z-index: 2;   
        left: -100%;

        &__active {
            left: 0;
        }
    }

    .header__wrapper {
        background-color: rgba($dark-color3, 0.9);
        width: 200px;      
    }

    .header__in {
        background-color: rgba($dark-color3, 0.9);
        height: 100dvh;
        flex-direction: column;
        justify-content: flex-start;
        position: relative;
    }

    .header__left {
        height: 50px;
        padding: 2rem 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid rgba($base-color1, 0.5);
        width: 100%;
    }
}


.nav__list {
    display: flex;

    &>li {
        &:not(:last-child) {
            margin-right: 40px;
        }

        >a {
            padding: 15px 0;
            font-size: 15px;
            display: inline-block;
            color: $base-color3;
            text-transform: uppercase;
            font-family: "Roboto", sans-serif;
            font-weight: 500;
            cursor: pointer;

            &:hover {
                color: $base-color1;
            }

            &.active {
                color: $base-color1;
            }
        }
    }
}


@media screen and (max-width: 991px) {
    .nav__list {
        flex-direction: column;
        margin-top: 1rem;
    }
}