@import "../../../assets/styles/variable";

.page404 {
  height: calc(100vh - 80px);
  display: flex;

  &__wrap {
    margin: auto;
    text-align: center;
  }

  h3 {
    font-size: 120px;
    color: $dark-color3;
    text-shadow:
      1px 1px 0 $base-color1,
      1px -1px 0 $base-color1,
      -1px 1px 0 $base-color1,
      -1px -1px 0 $base-color1;
  }

  h4 {
    font-size: 24px;
    color: $base-color1;
  }

  p {
    text-transform: uppercase;
    margin-bottom: 50px;
  }
}
