@import "../../../assets/styles/variable";


.about {   
    padding-bottom: 80px;

    & .block-left {
        position: relative;
        max-width: 50%;
    }

    @media screen and (max-width: 991px) {
        & .block-left {
            max-width: 100%;
        }
    }
}

.about__text {
    &-title {
        font-size: 36px;
        font-weight: 400;
        margin-bottom: 18px;
    }

    &-subtitle {
        font-size: 24px;
        font-weight: 300;
        color: $base-color1;
        margin-bottom: 18px;
    }

    &-description {
        margin-bottom: 18px;
    }   
}

.about__btn {
    margin-top: 40px;
}

.swiper-slide img {
    min-height: 570px;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;

    @media screen and (max-width: 991px) {
        & {
            min-height: auto;
        }
    }
}