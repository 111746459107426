@import "../../../assets/styles/variable";

.loader {
    position: fixed;
    z-index: 99999;
    height: 100dvh;
    width: 100vw;
    background-color:$dark-color2;

    svg {
        width: 100%;
        height: 100%;
    }

    svg text {
        animation: stroke 2s infinite alternate;
        stroke-width: 4;
        stroke: $base-color1;
        font-size: 400px;
    }

    @keyframes stroke {
        0% {
            fill: rgba(254, 197, 68, 0);
            stroke: rgba(254, 197, 68, 1);
            stroke-dashoffset: 25%;
            stroke-dasharray: 0 50%;
            stroke-width: 4;
        }

        70% {
            fill: rgba(254, 197, 68, 0);
            stroke: rgba(254, 197, 68, 1);
        }

        80% {
            fill: rgba(254, 197, 68, 0);
            stroke: rgba(254, 197, 68, 1);
            stroke-width: 3;
        }

        100% {
            fill: rgba(254, 197, 68, 1);
            stroke: rgba(254, 197, 68, 0);
            stroke-dashoffset: -25%;
            stroke-dasharray: 50% 0;
            stroke-width: 0;
        }
    }

    @media screen and (max-width: 991px) {
        svg text {
            font-size: 200px;
        }
    }
}