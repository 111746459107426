@import "../../../assets/styles/variable";

.portfolio {
    padding-bottom: 100px;

    &__loadMoreBtn {
        display: flex;
        width: 100%;
        margin-top: 30px;
    }

    & .btn {
        margin: auto;
    }
}

.grid {
    position: relative;
    gap: 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
}

/* Common style */
.grid figure {
    position: relative;
    overflow: hidden;
    max-width: 48%;
    border: 1px solid $base-color4;
    max-height: 350px;
    height: 350px;
    text-align: center;
    padding: 20px;
    background-color: $dark-color4;

    @media screen and (max-width: 991px) {
        & {
            max-width: 100%;
        }
    }

    @media screen and (max-width: 575px) {
        & {
            max-height: 250px;
        }
    }
}

.grid figure img {
    position: relative;
    min-height: 100%;
    max-width: 100%;
    object-fit: cover;
}

.grid figure figcaption {
    color: $base-color3;
    text-transform: uppercase;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;

    &:before,
    &:after {
        pointer-events: none;
    }
}

.grid figure h2,
.details {
    word-spacing: -0.15em;
    font-weight: 300;
    background-color: $dark-color2;
    font-size: 20px;
    text-transform: capitalize;
}

.grid figure h2 span {
    font-weight: 300;
    color: $base-color1;
}

.grid figure h2,
.grid figure p {
    margin: 0;
}

.grid figure p {
    letter-spacing: 1px;
    font-size: 18.5%;
}

/*-----------------*/
/***** Terry *****/
/*-----------------*/

figure.effect-terry figcaption {
    padding: 1em;
}

figure.effect-terry figcaption::before,
figure.effect-terry figcaption::after {
    position: absolute;
    width: 200%;
    height: 200%;
    border-style: solid;
    border-color: $dark-color3;
    content: '';
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
}

figure.effect-terry figcaption::before {
    right: 0;
    bottom: 0;
    border-width: 0 50px 42px 0;
    -webkit-transform: translate3d(70px, 60px, 0);
    transform: translate3d(70px, 60px, 0);
}

figure.effect-terry figcaption::after {
    top: 0;
    left: 0;
    border-width: 15px 0 0 15px;
    -webkit-transform: translate3d(-15px, -15px, 0);
    transform: translate3d(-15px, -15px, 0);
}

figure.effect-terry img,
figure.effect-terry p a {
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

figure.effect-terry img {
    opacity: 0.85;
}

figure.effect-terry h2,
.details {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 0.4em 10px;
    width: 51%;
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: translate3d(100%, 0, 0);
    transform: translate3d(100%, 0, 0);
}

.details {
    color: $base-color1;
    visibility: hidden;
    pointer-events: none;

    &:after,
    &:before {
        content: "";
        position: absolute;
        background-color: $base-color1;
        width: 12.5%;
        height: 2px;
        bottom: 6px;
        left: 50%;
        transform: translateX(-50%);
        visibility: hidden;
        opacity: 0;
        transition: all .3s ease;
    }

    &:before {
        left: 37.5%;
    }

    &::after {
        left: 62.5%;
    }

    @media (hover: hover) and (pointer: fine) {

        &:hover:before,
        &:hover:after {
            visibility: visible;
            opacity: 1;
        }

        &:hover:before {
            left: 44%;
        }

        &:hover:after {
            left: 56%;
        }
    }
}


@media screen and (max-width: 991px) {
    figure.effect-terry h2 {
        padding: 0.50em 10px;
        font-size: 120%;
    }
}

figure.effect-terry p {
    text-align: center;
    text-transform: capitalize;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    position: absolute;
    right: 10px;
    top: 0;
    padding-top: 20px;
    font-size: 25px;
    height: 100%;
}

figure.effect-terry p a {
    display: block;
    margin-bottom: 1em;
    color: $base-color2;
    opacity: 0;
    -webkit-transform: translate3d(90px, 0, 0);
    transform: translate3d(90px, 0, 0);
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

figure.effect-terry p a:hover,
figure.effect-terry p a:focus {
    color: $base-color1;
}

figure.effect-terry:hover figcaption::before,
figure.effect-terry:hover figcaption::after {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

figure.effect-terry:hover img {
    opacity: 0.4;
    transform: scale(1.2)
}

figure.effect-terry:hover .details,
figure.effect-terry:hover p a {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}


figure.effect-terry:hover .details {
    visibility: visible;
    transition: all .3s ease;
    background-color: $dark-color3;
    pointer-events: all;
}

figure.effect-terry:hover h2 {
    display: none;
}

figure.effect-terry:hover p a {
    opacity: 1;
}

figure.effect-terry:hover p a:first-child {
    -webkit-transition-delay: 0.025s;
    transition-delay: 0.025s;
}

figure.effect-terry:hover p a:nth-child(2) {
    -webkit-transition-delay: 0.05s;
    transition-delay: 0.05s;
}

figure.effect-terry:hover p a:nth-child(3) {
    -webkit-transition-delay: 0.075s;
    transition-delay: 0.075s;
    position: relative;
    color: $base-color1;

    &:after {
        position: absolute;
        content: "";
        width: 100%;
        height: 400%;
        border: 1px solid $base-color1;
    }

    &:hover:after {
        border-color: $base-color1;
    }
}