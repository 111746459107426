@import "../../../assets/styles/variable";

.social-link {
    display: flex;
    
    .social-btn {
        border-radius: 50px;
        background-color: $dark-color2;
        border: 0.5px solid $dark-color5;
        transition: all 0.4s ease;
        overflow: hidden;
        display: flex;
        align-items: center;
        position: relative;
        z-index: 998;

        &:not(:last-child) {
            margin-right: 20px;
        }

        .social-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50px;
            width: 50px;
            height: 50px;
            text-align: center;
            transition: all 0.4s ease;
            color:$base-color1;

            svg {
                scale: 1.5;
            }
        }

        .icon-name {
            font-weight: 600;
            color: $base-color3;
            overflow: hidden;
            max-width: 0;
            transition: all 0.5s ease;
        }

        &:hover,
        &.active {
            padding-right: 15px;

            .icon-name {
                max-width: 140px;
            }
        }
    }
}