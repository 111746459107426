@import "../../../assets/styles/variable";

.contact {
    padding-top: 40px;
    padding-bottom: 60px;
    background-color: $dark-color2;

    & .block-wrap {
        align-items: flex-start;
    }

    & input,
    & textarea {
        display: block;
        width: 100%;
        color: $base-color2;
        padding: 12px 40px 12px 16px;
        border: 1px solid $base-color4;
        background-color: $dark-color4;
        border-radius: 7px;
        transition: all 0.4s ease;
        text-transform: capitalize;

        &::placeholder {
            color: $dark-color5;
        }

        &:focus {
            border-color: $dark-color5;
            outline: 0;
        }
    }

    & textarea {
        height: 150px;
        resize: vertical;
    }

    & input:focus,
    & textarea:focus {
        background-color: $dark-color3;
    }
}

.contact__title {
    font-size: 30px;
    font-weight: 400;
    margin-top: -6px;
    margin-bottom: 22px;
}

.form {
    position: relative;

    &__input-row {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1.5rem;

        @media screen and (max-width: 574px) {
            & {
                flex-direction: column;
                gap: 0;
            }
        }
    }

    &__input-box {
        position: relative;
        margin-bottom: 24px;
        width: 100%;

        &.error span {
            color: rgba(red, 0.7);
        }

        & span {
            position: absolute;
            top: 0;
            line-height: 59px;
            color: $base-color1;
            right: 8px;
            font-size: 1.3em;
            background: inherit;
        }
    }

    &__loader {
        width: 25px;
        position: absolute;
        top: -50px;
        right: 0;
    }
}

.message {
    margin-bottom: 24px;

    &__error {
        color: rgba(red, 0.7);
    }

    &__success {
        color: $base-color1;
    }
}

.contact__text {
    margin-top: -6px;
    margin-bottom: 23px;
}


.contact__info {
    &-wrap {
        display: flex;
        align-items: flex-start;
        margin-bottom: 30px;

        &:hover {
            .contact__info-icon {
                border-color: $base-color1;
            }
        }
    }

    &-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.5px solid $dark-color5;
        border-radius: 50%;
        background-color: $dark-color2;
        width: 50px;
        height: 50px;
        margin-right: 20px;
        transition: all 0.4s ease;

        svg {
            scale: 1.4;
        }
    }

    &-details {
        h4 {
            font-size: 20px;
            font-weight: 400;
            margin-top: -4px;
            margin-bottom: 6px;
        }

        a {
            display: block;
            margin-bottom: -2px;
            color: $base-color2;

            &:hover {
                color: $base-color1;
            }
        }
    }
}

.contact__socialLinks {
    &-text {
        margin-top: 0;
        margin-bottom: 25px;
        font-size: 20px;
        font-weight: 500;
        padding-top: 5px;
    }

    & .social-link a:last-child {
        display: none;
    }
}