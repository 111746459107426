/*Google Fonts*/
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans&display=swap");


h1,
h2,
h3,
h4,
h3,
h6 { 
  color: $base-color3;
  padding: 0;
  margin: 0 0 10px 0;
  font-weight: 700;
  line-height: 1.2em;
}

