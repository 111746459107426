@import "../../../assets/styles/variable";

.scrollToTop {
    position: fixed;
    bottom:1vw;
    right: 1vw;
    font-size: 35px;
    border: 2px solid $dark-color5;
    color:$base-color2;
    border-radius:50%;
    cursor: pointer;
    z-index: 999;
    background-color:$dark-color1;    

    @media screen and (max-width: 991px) {
        & {
            bottom: 3vh;
            right: 3vh;
            font-size: 40px;
        }
    }

    @media (hover: hover) and (pointer: fine) {
        &:hover {
            background-color:$dark-color2;
            border-color: $base-color1;
            color:$base-color1;
        }
    }
}