@import "../../../assets/styles/variable";

.hamburger {
    width: max-content;
    height: max-content;
    position: fixed;
    right: 1rem;
    z-index: 9999;
    top: 1.5rem;
    transition: all 0.6s cubic-bezier(0.25, 1, 0.5, 1);
}

.hamburger__wrapper {
    margin: auto;
    width: 30px;
    height: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 4px;
    cursor: pointer;
}

.pix {
    width: 5px;
    height: 5px;
    background-color: #94a3b8;
    border-radius: 50%;
    transition: 0.3s ease-in-out;
}

.active {
    & .pix {
        &:nth-of-type(1) {
            transform: translate(0, 0);
        }

        &:nth-of-type(2) {
            transform: translate(-6px, 6px);
        }

        &:nth-of-type(3) {
            transform: translate(0, 0);
        }

        &:nth-of-type(4) {
            transform: translate(6px, 6px);
        }

        &:nth-of-type(5) {
            transform: translate(0, 0);
        }

        &:nth-of-type(6) {
            transform: translate(-6px, -6px);
        }

        &:nth-of-type(7) {
            transform: translate(0, 0);
        }

        &:nth-of-type(8) {
            transform: translate(6px, -6px);
        }

        &:nth-of-type(7) {
            transform: translate(0, 0);
        }
    }
}

@media screen and (min-width: 992px) {
    .hamburger {
        display: none;
    }
}

@media only screen and (max-width: 991px) {
    .hamburger {
        right: 1.5rem;
        left: auto;      

        &.minWidth {
            left: auto;
        }
    }

    .hamburger__wrapper:hover .pix {
        &:nth-of-type(1) {
            transform: none;
        }

        &:nth-of-type(3) {
            transform: none;
        }

        &:nth-of-type(7) {
            transform: none;
        }

        &:nth-of-type(9) {
            transform: none;
        }
    }

    .active:hover .pix {
        &:nth-of-type(1) {
            transform: none;
        }

        &:nth-of-type(2) {
            transform: none;
        }

        &:nth-of-type(3) {
            transform: none;
        }

        &:nth-of-type(4) {
            transform: none;
        }

        &:nth-of-type(6) {
            transform: none;
        }

        &:nth-of-type(7) {
            transform: none;
        }

        &:nth-of-type(8) {
            transform: none;
        }

        &:nth-of-type(9) {
            transform: none;
        }
    }

    .active .pix {
        &:nth-of-type(1) {
            transform: translate(0, 0) !important;
        }

        &:nth-of-type(2) {
            transform: translate(-6px, 6px) !important;
        }

        &:nth-of-type(3) {
            transform: translate(0, 0) !important;
        }

        &:nth-of-type(4) {
            transform: translate(6px, 6px) !important;
        }

        &:nth-of-type(5) {
            transform: translate(0, 0) !important;
        }

        &:nth-of-type(6) {
            transform: translate(-6px, -6px) !important;
        }

        &:nth-of-type(7) {
            transform: translate(0, 0) !important;
        }

        &:nth-of-type(8) {
            transform: translate(6px, -6px) !important;
        }

        &:nth-of-type(7) {
            transform: translate(0, 0) !important;
        }
    }
}