@import "../../../assets/styles/variable";


.resume {
    background-color: $dark-color2;
    padding-bottom: 60px;
    overflow: hidden;

    & .block-wrap {
        align-items: flex-start;
    }
}

.resume__heading {
    display: flex;
    align-items: center;
    margin-bottom: 50px;

    &-title {
        margin: 0;
        margin-left: 30px;
        font-size: 36px;
        font-weight: 400;
    }
}

.resume__timeline {
    position: relative;
    padding-top: 1px;
    margin-bottom: 50px;
    display: inline-block;
    width: 100%;

    &:last-child {
        margin-bottom: 0;
    }

    &::before {
        position: absolute;
        content: "";
        border: 2px solid $base-color4;
        border-radius: 50%;
        background-color: $dark-color4;
        width: 13px;
        height: 13px;
        top: 0px;
        left: -47px;
        transition: all 0.4s ease;
    }

    &:hover {
        &::before {
            background-color: $base-color1;
        }
    }

    &-wrap {
        padding-left: 40px;
        margin-left: 20px;
        border-left: 1px solid $base-color4;
    }

    &-title {
        font-size: 24px;
        font-weight: 400;
        color: $base-color1;
        margin-top: -7px;
        margin-bottom: 8px;
    }

    &-duration {
        display: flex;
        justify-content: space-between;

        font-size: 18px;
        margin-bottom: 21px;

        & span {
            font-size: 15px;
            opacity: .7;
        }
    }

    &-subtitle {
        font-size: 20px;
        font-weight: 400;
        margin-top: -10px;
        margin-bottom: 19px;

        & a {
            color: $base-color3;

            &:hover {
                color: $base-color1;
            }
        }
    }

    &-description {
        & li {
            padding-left: 12px;
        }

        & li:before {
            content: "▹ ";
            position: absolute;
            left: -5px;
        }
    }
}