@import "../../../assets/styles/variable";

.logo {
    border:2px solid $base-color1;
    background-color:$dark-color3;
    border-radius:50%;
    padding: 5px;
    position: relative;
    width:37px;
    height: 37px;
    cursor: pointer;
    transition: scale .3s ease;

    & img {
        width:28px;
        position: absolute;
    }  
}