* {
  scroll-behavior: smooth;
}

::-webkit-scrollbar {
  width: 0;
}

html,
body,
#root {
  height: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Montserrat', sans-serif;
  color: $base-color2;
  background-color: $dark-color3;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.6em;
  overflow-x: hidden;
  position: relative;
}

.sidebar-opened {
  overflow: hidden;
}

.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.main {
  flex: 1 0 auto;
}

.footer {
  flex: 0 0 auto;
}

.container {
  width: 100%;
  max-width: 1140px;
  padding: 0 15px;
  margin-right: auto;
  margin-left: auto;
  overflow-x: hidden;
}

@media (min-width: 1200px) {
  .container {
    max-width: 1140px;
  }
}

@media screen and (max-width: 1140px) {
  .container {
    max-width: 960px;
  }
}

@media screen and (max-width: 991px) {
  .container {
    max-width: 720px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    max-width: 540px;
  }
}

@media screen and (max-width: 578px) {
  .container {
    max-width: 540px;
    margin-right: auto;
    margin-left: auto;

  }
}

.btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  line-height: 1.5em;
  font-weight: 500;
  padding: 12px 1.5em;
  border-radius: 50px;
  min-width: 170px;
  border: none;
  outline: none;
  transition: all 0.4s ease;
  text-transform: capitalize;
  cursor: pointer;
  background-color: $base-color1;
  color: $dark-color2;

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-color: #e0a929;
      -webkit-box-shadow: inset 0px 0px 21px 0px rgba(0,0,0,0.75);
      -moz-box-shadow: inset 0px 0px 21px 0px rgba(0,0,0,0.75);
      box-shadow: inset 0px 0px 5px 0px rgba(0,0,0,0.75);

    }
  }
}

.height-100 {
  height: 100px;
}

.height-80 {
  height: 80px;
  background-color: $dark-color2;
  opacity: .7;
}



.height-25 {
  height: 25px
}

@media screen and (max-width: 991px) {
  .height-100 {
    height: 80px;
  }
}

.block {
  &-wrap {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-left,
  &-right {
    width: 100%;
  }

  &-left {
    padding-right: 45px;
  }

  @media screen and (max-width: 991px) {
    &-wrap {
      flex-direction: column;
      gap: 50px;

      & img {
        object-fit: contain;
      }
    }

    &-left {
      padding-right: 0;
    }
  }
}

.swiper {
  width: 100%;
  height: 100%;
}

.bgAttachment {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 850px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  z-index: -1;
  opacity: 0.4;


  &-min {
    min-height: 30vh;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
  }
}

@media screen and (max-width: 991px) {
  .bgAttachment {
    height: 710px;
    background-attachment: unset;

    &-min {
      display: none;
    }
  }

  .height-80 {
    display: none;
  }
}