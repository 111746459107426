@import "../../../assets/styles/variable";

.skills {
  background-color: $dark-color2;

  &__wrap {
    position: relative;
    z-index: 1;
    margin: 5px 0;

    &:after,
    &:before {
      content: "";
      position: absolute;
      background-color: $base-color1;
    }

    &:after {
      width: 75px;
      height: 5px;
      top: -5px;
      right: -5px;
    }

    &:before {
      width: 10px;
      height: 67px;
      right: -5px;
      top: 0;
    }

    @media screen and (max-width:991px) {
      & {
        overflow: hidden;

        &:before,
        &:after {
          display: none;
        }
      }
    }
  }


  &__list {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
    position: relative;

    &:after,
    &:before {
      content: "";
      position: absolute;
      background-color: $base-color1;
    }

    &:after {
      width: 75px;
      height: 5px;
      bottom: -5px;
      left: -5px;
    }

    &:before {
      width: 5px;
      height: 67px;
      left: -5px;
      bottom: 0;
    }

    & li {
      border: 1px solid $base-color4;
      background-color: $dark-color2;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 25px;
      width: 150px;
      padding: 30px;
      line-height: 0;
      position: relative;

      &:hover::before {
        opacity: 1;
      }

      &:before,
      &:after {
        border-radius: inherit;
        content: "";
        height: 100%;
        left: 0px;
        opacity: 0;
        position: absolute;
        top: 0px;
        transition: opacity 500ms;
        width: 100%;
      }

      &:before {
        background: radial-gradient(800px circle at var(--mouse-x) var(--mouse-y),
            rgba(255, 255, 255, 0.04),
            transparent 40%);
        z-index: 3;
      }

      &:after {
        background: radial-gradient(600px circle at var(--mouse-x) var(--mouse-y),
            rgba(255, 255, 255, 0.04),
            transparent 50%);
        z-index: 1;
      }

      & span {
        font-size: 18px;
        text-transform: capitalize;
      }
    }

    & li svg {
      font-size: 50px;
    }

    &:hover>.card::after {
      opacity: 1;
    }
  }


  @media screen and (max-width:575px) {

    &:before,
    &:after {
      display: none;
    }

    &__list li {
      max-width: 28.3333%;
      padding: 20px;
    }

    & li span {
      font-size: 15px;
    }

    & li svg {
      font-size: 40px;
    }
  }
}

.icon-bg {
  scale: 20;
  position: absolute;
  border: none;
  top: 50%;
  right: 0;
  z-index: -1;
  opacity: 1;
}



.html5 {
  color: #e44d26;
}

.css3 {
  color: #264de4;
}

.JavaScript {
  color: #f0db4f;
}

.jquery {
  color: #0769ad;
}

.ReactJs {
  color: #61dbfb;
}

.redux {
  color: #764abc;
}

.MUI {
  color: #00b0ff;
}

.scss {
  color: #cc6699;
}

.NodeJS {
  color: #3c873a;
}

.ExpressJS {
  color: #b0d4b6;
}

.PHP {
  color: #8a8daa;
}

.MySQL {
  color: #0893b3;
}

.MongoDB {
  color: #4db33d;
}

.Git {
  color: #f1502f;
}

.Gulp {
  color: $base-color2;
}

.Postman {
  color: #ef5b25;
}

.tailwindcss {
  color: #38BDF8;
}

.Powerlink {
  color: #c9c936;
}

.Monday {
  color: #6972c2;
}

.Jira {
  color: #7c3333;
}

.Photoshop {
  color: #026dc5;
}

.Illustrator {
  color: #cf0000;
}

.XD {
  color: #d800a6;
}

.Figma {
  color: #b659ff;
}

.jwt {
  color: #D500F9;
}

.swiper {
  color: #0080FF;
}

.cookie {
  color: #c2b435;
}

.Bitbucket {
  color:#1A74ED;
}

.Zustand {
  color:#9e856c;
}

.Vite {
  color:#FBC02D;
}

.TypeScript {
  color:#007acc;
}

.Laravel {
  color:#F05340;
}
.NestJs {
  color:#D5214F;
}