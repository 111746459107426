@import "../../../assets/styles/variable";

/* hero */
.single-p {
    overflow-x: hidden;
}

.single-p__hero {
    position: relative;
    height: 850px;

    @media screen and (max-width: 991px) {
        & {
            height: 710px;
        }
    }

    img {
        height: 100%;
        width: 100%;
        object-fit: contain;
    }

    &-wrap {
        height: 710px;
        display: flex;
        align-items: center;
        z-index: 11;

        & .container {
            width: 100%;
        }
    }

    &-img {
        position: absolute;
        right: 1vw;
        top: 0;
        height: 710px;
        width: 50%;
        transition: "all 0.3s easy";
    }

    &-text {
        max-width: 47%;

        h1,
        h2 {
            line-height: 1;
            animation-name: fade;
            animation-duration: 800ms;
        }

        h1 {
            font-size: 90px;
            font-weight: 900;
            color: $base-color3;
        }

        h2 {
            font-size: 32px;
            margin-bottom: 21px;
            font-weight: 300;
            color: $base-color2;
        }

        @keyframes fade {
            0% {
                transform: scale(0.5);
                transition-delay: 3s;
                opacity: 0;
            }

            100% {
                transform: scale(1);
                transition-delay: 3s;
                opacity: 1;
            }
        }
    }

    &-btns {
        background-color: $dark-color4;
        border: 1px solid $base-color4;
        border-right: 0;
        border-radius: 50px;
        padding: 5px 12px;
        width: max-content;
        display: flex;
        align-items: center;
        animation-name: fade;
        animation-duration: 800ms;

        & .social-link {
            position: relative;
        }
    }

    @media screen and (max-width: 1142px) {
        .single-p__hero {
            &-text {
                h1 {
                    font-size: 75px;
                }

                h2 {
                    font-size: 30px;
                }
            }
        }
    }

    @media screen and (max-width: 991px) {
        .single-p__hero {
            &-img {
                display: none;
            }

            &-text {
                max-width: 100%;
                text-align: center;
            }

            &-btns {
                margin: auto;
            }
        }
    }


    @media screen and (max-width: 575px) {
        .single-p__hero {
            &-wrap {
                height: 610px;
            }

            &-text {
                h1 {
                    font-size: 50px;
                }

                h2 {
                    font-size: 19px;
                    line-height: 1.3;
                }
            }
        }
    }
}

/* hero end*/

.single-p__concept {
    margin-top: -40px;
}

/* technologies */
.single-p__technologies {
    padding-bottom: 20px;

    &__wrap {
        display: flex;
        position: relative;
        padding-bottom: 50px;
        z-index: 1;
    }

    &__list {
        margin: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;

        & li {
            font-size: 40px;
            border: 1px solid $base-color4;
            padding: 10px;
            position: relative;

            &:hover span {
                visibility: visible;
            }
        }

        & li span {
            display: block;
            position: absolute;
            visibility: hidden;
            font-size: 15px;
            text-align: center;
            bottom: -50px;
            left: 50%;
            transform: translateX(-50%);
            color: $base-color1;
            padding: 5px 12px;
            border: 1px solid $base-color4;
            width: max-content;
            text-transform: uppercase;
            z-index: 9;
            background-color: $dark-color3;
        }
    }
}

/* technologies end*/

.text-center {
    background-color: $dark-color3;
    padding: 50px 0;

    p {
        line-height: 1.6;
        font-size: 18px;
        font-weight: 300;
        text-align: center;
    }
}

.list {
    margin-top: 30px;
    max-width: 49%;

    & li {
        padding-left: 12px;
        position: relative;
        font-size: 17px;
        margin-bottom: 14px;
    }

    & li:before {
        content: "▹ ";
        position: absolute;
        left: -5px;
    }

    &-right {
        margin-left: auto;
    }

    &-fullwith {
        max-width: 100%;
    }

    @media screen and (max-width: 1142px) {
        & {
            max-width: 100%;
        }
    }
}

.bg-dark2 {
    background-color: $dark-color2;
}

/* description */
.single-p__description {
    padding: 50px 0;
    position: relative;
    height: 710px;

    &-wrap {
        display: flex;
        align-items: flex-start;

        & .container {
            width: 100%;
        }

        & a {
            color: $base-color1
        }
    }

    &-img {
        position: absolute;
        right: 4vw;
        top: 53%;
        transform: translateY(-50%);
        max-width: 45%;


        & img {
            object-fit: contain;
        }
    }

    @media screen and (max-width: 1142px) {
        & {
            height: max-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 30px;
        }

        &-wrap {
            order: 2;
        }

        &-img {
            max-width: 50%;
            transform: unset;
            position: static;
        }
    }

    @media screen and (max-width: 991px) {
        &-img {
            max-width: 90%;
        }
    }
}

/* description end*/

/* features */
.single-p__features {
    padding: 50px 0;
    position: relative;
    height: 760px;
    background-color: $dark-color3;

    &-wrap {
        display: flex;
        align-items: flex-start;

        & .container {
            width: 100%;
        }

        & a {
            color: $base-color1
        }
    }

    &-img {
        position: absolute;
        left: 2vw;
        top: 53%;
        transform: translateY(-50%);
        max-width: 45%;
        z-index: 1;

        & img {
            object-fit: contain;
        }
    }

    @media screen and (max-width: 1142px) {
        & {
            height: max-content;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: 30px;

            & .sectionHeading {
                width: calc(100vw - 40px);
            }
        }

        &-wrap {
            order: 2;
        }

        &-img {
            max-width: 50%;
            transform: unset;
            position: static;
        }
    }

    @media screen and (max-width: 991px) {
        &-img {
            max-width: 90%;
        }
    }
}

/* features end*/

/* results */
.single-p__results {

    padding: 50px 0;
    position: relative;
    background-color: $dark-color3;

    &-wrap {
        display: flex;
        align-items: flex-start;
        z-index: 11;


        & .container {
            width: 100%;
        }
    }
}

/* results end*/