@import "../../../assets/styles/variable";

footer {
    padding: 20px 0;
}
.footer-wrap {    
    height: 72px;
    display: flex;
    justify-content: center;
    align-items: center;
}