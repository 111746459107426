@import "../../../assets/styles/variable";

.hero {
    position: relative;
    height: 850px;
    background-color: #080e1e;
    overflow-x: hidden;
    margin-top: 80px;

    @media screen and (max-width: 991px) {
        & {
            margin-top: 0;
            height: 710px;
        }
    }


    img {
        height: 100%;
        width: 100%;
        object-fit: cover;
    }
}

.hero__wrapper {
    height: 710px;
    display: flex;
    align-items: center;
    z-index: 11;

    & .container {
        width: 100%;
    }
}

.hero__text {
    h1 {
        font-size: 110px;
        margin-bottom: 24px;
        font-weight: 900;
        color: $base-color3;
        line-height: 1;
        animation-name: fade;
        animation-duration: 800ms;
    }

    h2 {
        font-size: 32px;
        margin-bottom: 41px;
        font-weight: 300;
        color: $base-color2;
        animation-name: fade;
        animation-duration: 800ms;
    }

    h3 {
        font-size: 30px;
        margin-top: -6px;
        margin-bottom: 10px;
        font-weight: 300;
        color: $base-color1;
        animation-name: fade;
        animation-duration: 800ms;
    }


    @keyframes fade {
        0% {
            transform: scale(0.5);
            transition-delay: 3s;
            opacity: 0;
        }

        100% {
            transform: scale(1);
            transition-delay: 3s;
            opacity: 1;
        }
    }

    @media screen and (max-width: 1142px) {
        & h1 {
            font-size: 90px;
        }
    }

    @media screen and (max-width: 575px) {
        & {          
            h1 {
                font-size: 15vw;
            }

            h2 {
                font-size: 6vw;
            }

            h3 {
                font-size: 6vw;
            }
        }
    }
}

.hero__img {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    width: 45%;
    transition: "all 0.3s easy";
}

@media screen and (max-width: 991px) {
    .hero__img {
        display: none;
    }
}

.hero__socialLinks {
    background-color: $dark-color4;
    border: 1px solid $base-color4;
    border-right: 0;
    border-radius: 50px 0 0 50px;
    height: 80px;
    width: 448px;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 30px;
    padding-left: 12px;
    right: 100%;
}